<template>
  <CommonPages id="detail" classs="detail">
    <div class="book-nav-row">
      <div class="book-nav-item cursor" v-bind="activeTestament" @click="toggleNav()">
        {{ capitalizeFirstLetter(activeTestament) }}
        <img v-show="showNav" class="book-nav-item-arrow" src="@/assets/arrow.png" alt="">
        <img v-show="!showNav" class="book-nav-item-arrow rotate" src="@/assets/arrow.png" alt="">
      </div>
    </div>

    <div class="book-detail-row pc_padding m_set_width">
      <div v-show="showNav" class="book-list-nav-row">
        <div class="book-list-nav-left">
          <template v-if="activeTestament == 'Old Testament'">
            <div v-for="(book,i) in oldTestamentBooks" :key="book.abbrev"
              class="book-list-nav-item cursor" :class="{'active':book.abbrev == abbrev}"
              @click="chooseBook(book.abbrev,book.name,i)">
              {{ book.name }}
            </div>
          </template>
          <template v-if="activeTestament != 'Old Testament'">
            <div v-for="(book,i) in newTestamentBooks" :key="book.abbrev"
              class="book-list-nav-item cursor" :class="{'active':book.abbrev == abbrev}"
              @click="chooseBook(book.abbrev,book.name,i)">
              {{ book.name }}
            </div>
          </template>

          <div class="other-book">
            <div v-if="activeTestament == 'Old Testament'" class="other-book-item cursor"
              @click="chooseNewOld('New')">
              New Testament
            </div>
            <div v-if="activeTestament != 'Old Testament'" class="other-book-item cursor"
              @click="chooseNewOld('Old')">
              Old Testament
            </div>
          </div>
        </div>
        <div :class="`book-list-nav-chapter-list book-list-nav-chapter-list-${deviceType}`">
          <div v-for="chapters in chapterContent" :key="chapters.chapter"
            :class="{'active':chapters.chapter == currentChapter}"
            class="book-list-nav-item  cursor" @click="chooseChapter(chapters.chapter)">
            {{ chapters.chapter }}
          </div>
        </div>
      </div>

      <div class="chapter-container">
        <div class="chapter-catalogue">
          <div class="chapter-catalogue-point" />
          {{ bookname }}
        </div>

        <div class="chapter-row">
          <template v-for="(verses,i) in versesContent">
            <div :key="`verses_${i}`" class="chapter-item">
              <div class="chapter-index">
                {{ currentChapter }}:{{ i+1 }}
              </div>
              <div class="chapter-item-context" v-html="verses" />
            </div>
            <AdComponent
              v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_insert && shouldInsertAdDeviece('detailInsert',i) !== -1"
              :key="`ad_${i}`" :ref="'ads-detail-insert' + i" class="ads-insert-100 ads-insert  "
              :ads="adsensConfig?.detail_insert?.[shouldInsertAdDeviece('detailInsert',i)]"
              :showDebug="showDebug" />
          </template>
        </div>

        <div v-if="activeTestamentBooks && deviceType != 'mobile'" class="chapter-nav-row">
          <div class="chapter-nav-item cursor" :class="{'active':currentChapter>1}"
            @click="gotobeginning()">
            <img src="@/assets/chapter-arrow.png" alt="">
            Beginning of Chapter
          </div>
          <div class="chapter-nav-item cursor"
            :class="{'active':parseInt(activeIndex)+1 < activeTestamentBooks.length}"
            @click="nextPage()">
            Next Chapter
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>

        <div v-if="activeTestamentBooks && deviceType == 'mobile'" class="chapter-nav-row mobile">
          <div class="chapter-nav-item cursor mobile"
            :class="{'active':parseInt(activeIndex)-1 >=0}" @click="prevPage()">
            <img src="@/assets/chapter-arrow.png" alt="">
          </div>
          <div class="chapter-nav-item cursor mobile"
            :class="{'active':parseInt(activeIndex)+1 < activeTestamentBooks.length}"
            @click="nextPage()">
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_insert?.[0]"
      ref="ads-detail-insert-1" class="ads-insert-max pc_padding padding-horizon-40 m-t-40"
      :ads="adsensConfig?.detail_insert?.[0]" :showDebug="showDebug" />
    <!-- <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_1"
      ref="ads-type-insert-1" class="ads-insert-full pc_padding padding-horizon-40"
      :ads="adsensConfig?.home_1" :showDebug="showDebug" />

    <div class="dom-father-row ">
      <template v-for="(data,i) in dietTypeList">
        <div :key="`dishes-item-${i}`" class="dishes-display-item"
          @click="selectDiet(formatDietName(data.name))">
          <img v-lazy="data.imageUrl?data.imageUrl:''" class="dishes-item-img" alt="">
          <div class="dished-item-name">
            {{ data.name }}
          </div>
        </div>
        <AdComponent
          v-if="$global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_insert && shouldInsertAdDeviece('homeInsert',i) !== -1"
          :key="`ad_${i}`" :ref="'ads-recipe-insert' + i"
          class="ads-recipe-insert ads-insert-max ads-insert  "
          :ads="adsensConfig?.home_insert?.[shouldInsertAdDeviece('homeInsert',i)]"
          :showDebug="showDebug" />
      </template>
    </div> -->
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      showNav: true,
      activeIndex: null,
      activeTestament: null,
      activeTestamentBooks: null,
      bookInfo: null,
      bookname: null,
      abbrev: null, // 可以根据路由参数或其他方式获取
      books: [],
      chapterContent: null,
      versesContent: null,
      currentChapter: null,
      card: [
        {
          img: require('@/assets/blog-1.png'),
          title: 'In the beginning God created the heaven and the earth.',
          desc: "And the earth was without form, and void; and darkness was upon the face of the deep. And the Spirit of God moved upon the face of the waters.",
          routename: 'blog1',
        },
        {
          img: require('@/assets/blog-2.png'),
          title: 'And God said, Let there be light',
          desc: "And God made the firmament, and divided the waters which were under the firmament from the waters which were above the firmament: and it was so.……",
          routename: 'blog2',
        },
        {
          img: require('@/assets/blog-3.png'),
          title: 'And God called the firmament Heaven. And the evening and the morning w……',
          desc: "And God said, Let the earth bring forth grass, the herb yielding seed, and the fruit tree yielding fruit after his kind, whose seed is in itself, upon the earth: and it was so.",
          routename: 'blog3',
        },
        {
          img: require('@/assets/blog-4.png'),
          title: 'And God set them in the firmament of the heaven to give light upon the earth',
          desc: "And God said, Let the waters bring forth abundantly the moving creature that hath life, and fowl that may fly above the earth in the open firmament of heaven.",
          routename: 'blog4',
        },

      ],
      hasInitBooksFromServer: false,
      /////////列表示例
      movieList: [],
      hasNext: true,
      page: 1,
      observedAds: null

    };
  },
  computed: {
    title () {
      return `${this.activeTestament} | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return this.activeTestament == 'Old Testament' ? `Explore the Old Testament on ${this.domainConfig['IAmURL']}. Delve into ancient scriptures and stories with seamless access and various translations.` : `Discover the New Testament on ${this.domainConfig['IAmURL']}. Dive into the life and teachings of Jesus Christ with easy navigation and multiple translations.`
    },
    oldTestamentBooks () {
      return this.books.filter(book => book.testament === 'Old Testament');
    },
    newTestamentBooks () {
      return this.books.filter(book => book.testament === 'New Testament');
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    //获取参数示例
    const { abbrev, bookname, type, chapter } = this.$route.params;
    console.log(`index-abbrev: ${abbrev} bookname: ${this.formatName(bookname)} type:${this.formatName(type)} chapter:${chapter}`);
    if (this.deviceType == 'mobile') {
      this.showNav = false
    }
    if (this.$store.state.books.length > 0) this.hasInitBooksFromServer = true
    this.books = this.$store.state.books
    let indexabbrev = abbrev.split('-')
    this.abbrev = indexabbrev[1]
    this.activeIndex = indexabbrev[0]
    this.bookname = this.formatName(bookname)
    this.activeTestament = this.formatName(type)
    this.currentChapter = chapter || 1
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },

  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    if (!this.hasInitBooksFromServer) this.fetchBooks();
    await this.fetchChapter();//获取节列表
    this.selectChapter(this.currentChapter) //选择第一节

    // this.getImages()
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
    // 监听滚动事件示例
    // this.onScroll = this.debounce(this.onScroll.bind(this), 300);
    // window.addEventListener('scroll', this.onScroll);
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 滚动监听移除示例
    // window.removeEventListener('scroll', this.onScroll);
    if (this.checkInterval) {
      clearInterval(this.checkInterval); //客户端交互移除
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);//客户端交互移除
    }
  },
  methods: {
    toggleNav () {
      this.showNav = !this.showNav
    },
    formatName (name) {
      return name
        .split('-') // 按 "-" 拆分单词
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // 将首字母大写
        .join(' ');
    },
    transformName (name) {
      return name
        .split(' ')
        .map(word => word.charAt(0).toLowerCase() + word.slice(1))
        .join('-');
    },
    gotobeginning () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(this.bookname)}/${this.activeIndex}-${this.abbrev}/1${fullQueryString}`
    },

    async prevPage () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      let index = parseInt(this.activeIndex) - 1
      let abbrev = this.activeTestamentBooks[index].abbrev
      let bookname = this.activeTestamentBooks[index].name
      console.log(bookname, abbrev);
      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(bookname)}/${index}-${abbrev}${fullQueryString}`
    },
    async nextPage () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      let index = parseInt(this.activeIndex) + 1
      let abbrev = this.activeTestamentBooks[index].abbrev
      let bookname = this.activeTestamentBooks[index].name
      console.log(bookname, abbrev);
      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(bookname)}/${index}-${abbrev}${fullQueryString}`
    },
    async chooseNewOld (elder) {

      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      // if (this.activeTestament == `${elder} Testament`) return
      // if (elder == 'Old') {
      //   this.activeTestamentBooks = this.oldTestamentBooks
      // } else {
      //   this.activeTestamentBooks = this.newTestamentBooks
      // }
      let activeTestament = `${elder} Testament`
      let abbrev = this[`${elder.toLowerCase()}TestamentBooks`]?.[0]?.abbrev
      let activeIndex = 0
      let bookname = this[`${elder.toLowerCase()}TestamentBooks`]?.[0]?.name
      // console.log(`${elder.toLowerCase()}TestamentBooks`);
      // console.log(this[`${elder.toLowerCase()}TestamentBooks`])
      // await this.fetchChapter()
      // this.selectChapter(1)
      console.log(activeTestament, abbrev, activeIndex, bookname);
      window.location.href = `${channel}/${this.transformName(activeTestament)}/${this.transformName(bookname)}/${activeIndex}-${abbrev}${fullQueryString}`

    },
    syncHeights () {
      const left = document.querySelector('.book-list-nav-left');
      const right = document.querySelector('.book-list-nav-chapter-list-pc');

      if (left && right) {
        const leftHeight = left.offsetHeight;
        right.style.height = `${leftHeight}px`;
        right.style.overflowY = 'auto'; // 如果内容超出，添加滚动
      }
    },
    capitalizeFirstLetter (str) {
      if (!str) return str; // 如果字符串为空，直接返回
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    //页面方法 ...
    async chooseBook (abbrev, name, index) {
      console.log(`abbrev:${abbrev}, name:${name}, index:${index}`);
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      // if (this.abbrev == abbrev) return
      // this.abbrev = abbrev
      // this.bookname = name
      // this.activeIndex = index
      // await this.fetchChapter()
      // this.selectChapter(1)

      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(name)}/${index}-${abbrev}${fullQueryString}`

    },
    async getBookInfo (abbrev) {
      try {
        const params = {
          abbrev: abbrev,
        };

        const res = await this.$global.server.fetchBookInfo(params);
        console.log(res)
        this.bookInfo = res.data
        console.log(`书籍 ${this.bookInfo.abbrev} 属于 ${this.bookInfo.testament}，索引为 ${this.bookInfo.index}`);
        this.activeIndex = res.data.index
        this.activeTestament = res.data.testament
      } catch (err) {
        console.log(err)
      }
    },
    async fetchBooks () {
      try {
        const res = await this.$global.server.fetchBooks();
        console.log(res)
        this.books = res.data
        this.$nextTick(() => {
          console.log(this.activeTestament);
          console.log(this.oldTestamentBooks);
          if (this.activeTestament == 'Old Testament') {
            this.activeTestamentBooks = this.oldTestamentBooks
          } else {
            this.activeTestamentBooks = this.newTestamentBooks
          }
        });
      } catch (err) {
        console.log(err)
      }
    },
    async fetchChapter () {
      try {
        const params = {
          abbrev: this.abbrev,
        };

        const res = await this.$global.server.fetchChapter(params);
        console.log(res)
        this.chapterContent = res.data.chapters;
        console.log('chapter done');

      } catch (err) {
        console.log(err)
      }
    },
    async selectChapter (chapterNumber) {
      try {
        const params = {
          abbrev: this.abbrev,
          chapter: chapterNumber,
        };

        const res = await this.$global.server.fetchChapter(params);
        console.log(res)
        this.versesContent = res.data.verses;
        this.currentChapter = chapterNumber;

        // 初始同步高度
        this.syncHeights();

        // 监听窗口变化，重新同步高度
        window.addEventListener('resize', this.syncHeights);
      } catch (err) {
        console.log(err)
      }
    },
    chooseChapter (chapterNumber) {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(this.bookname)}/${this.activeIndex}-${this.abbrev}/${chapterNumber}${fullQueryString}`
    },

    //获取列表 示例
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.postFUC(params);
        console.log(res)
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
        // 数据更新后，等待 DOM 更新完成再观察一遍广告！
        this.$nextTick(() => {
          this.observeAds();
        });
      } catch (err) {
        console.log(err)
      }
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos]?.indexOf(position) : this.adPosConfig?.[`${pos}PC`]?.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);
      if (!adsElements.length) {
        console.log('No ad elements to observe');
        return; // 如果没有广告元素，直接返回
      }

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && adElement instanceof HTMLElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //跳转
    goto (routername) {
      window.location.href = `/${routername}`
    },

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
